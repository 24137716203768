/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiTranslate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';
import {OBJECT_TYPE} from "../constants/object-type";

import template from './search-select.html';

import resources from '../../../components/api/resources/resources';

const MODULE_NAME = 'search-select';

angular.module(MODULE_NAME, [
  resources,
  uiSelect,
  uiBootstrap,
  uiTranslate
])

  .component('adnSearchSelect', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      model: '<',
      objectType: '@',
      selectObj: '<',
      objParam: '@',
      allowClear: '@',
      required: '@',
      fieldName: '@',
      transText: '@',
      onSelectParam: '@',
      multiple: '@',
      special: '@',
      specialObj: '<',
      disabled: '<'
    },
    controller: function(searchResource, LineItem, CreativeSet, $timeout) {
      const ctrl = this;

      ctrl.$onInit = function() {
        const obj = OBJECT_TYPE[ctrl.objectType];
        ctrl.param = ctrl.objParam || obj.apiParam;
        ctrl.transParam = ctrl.transParam || obj.apiParam;
        ctrl.required = ctrl.required === 'true' || ctrl.required === true;
        ctrl.allowClear = ctrl.allowClear === 'true' || ctrl.allowClear === true;
        ctrl.multiple = ctrl.multiple === 'true' || ctrl.multiple === true;

        let prevSearchText = null;
        let prevAdv = null;
        let searchTimeout;
        ctrl.search = function(searchElement) {
          if (_.has(searchElement, 'open') && !searchElement.open) {
            return;
          }
          let advertiserId = _.get(ctrl, ['specialObj', 'id']);
          if (prevSearchText === searchElement.search && prevAdv === advertiserId) {
            return;
          }
          $timeout.cancel(searchTimeout);
          searchTimeout = $timeout(function() {
            if (ctrl.special && ctrl.special.indexOf("ADVERTISER") > -1) {
              const params = {
                filterBy: 'name',
                filterByLike: searchElement.search
              };
              if (advertiserId) {
                params.advertiser = advertiserId;
              }
              params.pageSize = 50;
              const resource = ctrl.special === 'LINE_ITEM_ADVERTISER' ? LineItem : CreativeSet;
              prevSearchText = searchElement.search;
              prevAdv = advertiserId;
              resource.query(params, function(data) {
                ctrl.dataResults = data.results.length === 0 ? [{name: "No matching results found"}] : data.results;
              });
            } else {
              prevSearchText = searchElement.search;
              prevAdv = null;
              searchResource.query({"q": searchElement.search, "types": obj.type, "pageSize": 50}).then(function(data) {
                ctrl.dataResults = data.searchResults;
              });
            }
          }, 150);
        };

        ctrl.onSelect = function() {
          // need to handle the debounce to pass the value on
          ctrl.selectObj = _.get(ctrl.selectObj, 'onSelect') ? ctrl.selectObj : {onSelect: _.noop};
          ctrl.selectObj.onSelect(ctrl.onSelectParam);
        };

        ctrl.ready = true;
      };
    }
  });

export default MODULE_NAME;