/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import ngFileUpload from 'ng-file-upload';
import _ from 'lodash';
import {ApiConfig} from "../api/api";

const MODULE_NAME = "adn-checker-helper";

angular.module(MODULE_NAME, [
  ngFileUpload
])

  .factory('adnChecker', function($http, $q) {
    const ONE_HOUR = 60 * 60 * 1000;
    const TEN_MINUTES = 10 * 60 * 1000;

    let blockedCreatives;
    let timing = 0;

    return {
      getBlockedCreatives: function(forceCall) {
        if ((!forceCall || (forceCall && Date.now() - TEN_MINUTES < timing)) && blockedCreatives && (Date.now() - ONE_HOUR < timing)) {
          return $q.when(blockedCreatives);
        }
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnchecker', 'creativeblocks']), {
          params: _.assign({}, {format: 'json'})
        }).then(function(page) {
          timing = Date.now();
          blockedCreatives = page.data;
          return blockedCreatives;
        });
      },
      scanResultsByCreative: function() {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnchecker', 'creativeblocks']), {
          params: _.assign({}, {format: 'json'})
        }).then(_.iteratee('data'));
      },
      scanResults: function() {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnchecker', 'creativeblocksbynetwork']), {
          params: _.assign({}, {format: 'json'})
        }).then(_.iteratee('data'));
      },
      checkId: function(id, forceRerun, hashResults, ignoreScanHistory) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnchecker', id]), {
          params: _.assign({}, {format: 'json', concise: true, forceRerun: !!forceRerun, hashResults: !!hashResults, ignoreScanHistory: !!ignoreScanHistory})
        }).then(_.iteratee('data'));
      }
    };
  });

export default MODULE_NAME;