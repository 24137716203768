import * as _ from "lodash";
import {Uuid} from "../../components/util/uuid";
import {ApiConfig} from "../../components/api/api";
import {NetworkData, REGISTERED_URLS} from "./registered-urls-data";
import {LocalNetworkInfo} from "../../components/session/local-network-info";

const BROWSER_TEST_URL_PREFIX = "browser-test";
const BROWSER_TEST_ENVS = ["localhost", "coreuidist"];

function getBrowserTestData(idSuffix: string): NetworkData {
  return {
    id: 'puppet-ss-network' + idSuffix,
    interface: 'BROWSER_TEST' + idSuffix,
    urlSection: BROWSER_TEST_URL_PREFIX + idSuffix,
    env: BROWSER_TEST_ENVS,
    hasAnonymousCampaigns: true,
    showCoupons: true
  };
}

function getSafeWin(win) {
  return _.isFunction(win.$get) ? win.$get() : win;
}

export function getRegisteredUrl(win, networkId?): NetworkData {
  const safeWin = getSafeWin(win);
  const url: string = safeWin.location.pathname;
  const domain: string = safeWin.location.host;
  const search: string = safeWin.location.search;
  const envId = ApiConfig.obtain().getEnv();
  const networkInfoThemeId = LocalNetworkInfo.obtain().getSuppInfo('themeId');
  if (url.indexOf('/' + BROWSER_TEST_URL_PREFIX) === 0 && BROWSER_TEST_ENVS.indexOf(envId) > -1) {
    const slashIndex = url.indexOf("/", 1);
    const browserIdSuffix = url.substring(1, (slashIndex > -1 ? slashIndex : url.length)).replace(BROWSER_TEST_URL_PREFIX, "");
    return getBrowserTestData(browserIdSuffix);
  }
  return _.find(REGISTERED_URLS, (v) => {
    return (domain === v.domain || search.indexOf(v.urlSection) > -1 || networkInfoThemeId === v.id || networkId === v.id) && v.env.indexOf(envId) > -1;
  });
}

export function getUrl(win, suffix): string {
  const registeredUrl = getRegisteredUrl(win);
  const safeWin = getSafeWin(win);
  return registeredUrl ? safeWin.location.host === registeredUrl.domain ? suffix : '/' + registeredUrl.urlSection + suffix : "invalid-url-to-redirect-to-404" + Uuid.generate();
}
