import angular from 'angular';
import _ from 'lodash';
import uiRouter from '@uirouter/angularjs';

import {AUTH_EVENT, PERMISSIONS} from "../../components/auth/auth-constants";

import clickOutside from '../common/directives/click-outside-directive';
import BUILD_INFO from '../../gen-config/build-info';
import localNetworkProfile from '../../components/session/local-network-profile';
import localUserPermissions from '../../components/session/local-user-permissions';
import workspaceRegistry from '../../components/workspace-registry';
import sessionInfo from './session-info/session-info-directive';
import simpleModule from './simple/simple-module';
import nudeModule from './nude/nude-module';
import masqBanner from '../common/directives/banners/masq-banner-component';
import ghosteryBanner from '../common/directives/banners/ghostery-banner-component';
import browserUpdateBanner from '../common/directives/banners/browser-update-banner-component';
import moment from 'moment';

import networkTabDir from './tabs/networks-tab-directive';
import lastVerbedTabs from './tabs/last-verbed-tab-directive';
import notifTab from './tabs/notification-tab-directive';

import userProfileTemplate from './session-info/user-profile.html';
import newButtonTemplate from './base-new-button.html';

import template from './base.html';
import {ApiConfig, MauiConfig} from "../../components/api/api";
import {getRegisteredUrl} from "../config/registered-urls";
import {SESSION_EVENT} from "../common/constants/misc-constants";
import releaseNotesTemplate from "./release-notes-modal.html";
import {Session} from "../../components/session/session";
import releaseNotesDisplayComponent from "./simple/release-notes-display-component";
import releaseNotesHelper from "../../components/util/release-notes-helper";

class Base {
  constructor($scope, $state, $rootScope, $uibModal, $location, Network, $window, $document, releaseNotesHelper, UserProfile, $templateCache, localUserProfile, LocalUserPermissions, LocalNetworkProfile, WorkspaceRegistry) {
    const ctrl = this,
      userPermissions = LocalUserPermissions.get();
    let prevState = '';

    ctrl.masq = {
      supportUserId: localUserProfile.get().supportUserId,
      username: localUserProfile.get().username
    };

    ctrl.isSelfServiceOwn = LocalUserPermissions.isSelfServiceOwn();

    ctrl.app = localUserProfile.getCurrentApp();
    ctrl.apps = _.filter(LocalUserPermissions.getApps(), function(app) {
      return app !== 'SELF_SERVICE';
    });

    const regUrl = getRegisteredUrl($window, LocalNetworkProfile.get('networkId'));
    if (regUrl && regUrl.favicon) {
      const favicon = $document[0].getElementById("favicon");
      favicon.href = regUrl.favicon.href;
    }

    ctrl.switchApp = function(newApp) {
      localUserProfile.set("app", newApp);
      $state.go('app.home', null, {inherit: false, reload: true});
    };

    $templateCache.put("userProfileTemplate.html", userProfileTemplate);
    $templateCache.put("newButtonHtml.html", newButtonTemplate);

    let localUser = localUserProfile.get();
    if (!localUser) {
      return;
    }
    Network.query({ids: localUser.networkTagIds, minimal: true, useNetworkTags: true}, function(page) {
      ctrl.networks = {};
      ctrl.networks.ADVERTISING = _.filter(page.results, function(n) {
        return n.advertisingPlatform;
      });
      ctrl.networks.DATA = _.filter(page.results, function(n) {
        return n.dataPlatform;
      });
      ctrl.currentNetwork = _.find(ctrl.networks[ctrl.app], ['id', LocalNetworkProfile.get('networkId')]);
      ctrl.gravatarSize = (ctrl.networks[ctrl.app] || []).length > 1 ? 13 : 22;
    });

    function getReleaseNoteApps() {
      const tags = [];
      if (LocalNetworkProfile.isMarketplacePlatform()) {
        tags.push("MARKETPLACE");
      }
      if (!LocalNetworkProfile.isPureMarketplacePlatform()) {
        tags.push("ADSERVER");
      }
      return tags;
    }

    function checkReleaseNotes() {
      const lastRead = _.get(localUser, ['profile', 'adnPrefs', 'lastReleaseNotesRead']);
      releaseNotesHelper.query(getReleaseNoteApps(), true, 'ACTIVE', lastRead).then(function(data) {
        ctrl.showReleaseNotesNotification = data.length > 0;
      });
    }

    ctrl.showReleaseNotesNotification = _.get(localUser, ['profile', 'adnPrefs', 'showReleaseHighlight'], 'SHOW') === 'SHOW';
    if (ctrl.showReleaseNotesNotification) {
      checkReleaseNotes();
    }

    ctrl.perms = LocalUserPermissions.getAllNetworkPermissions();
    ctrl.anyPerms = LocalUserPermissions.getAnyVisibility();
    ctrl.sectionPerms = LocalUserPermissions.getSectionPerms(localUserProfile.getCurrentApp());

    ctrl.animationClass = '';
    $scope.$on('$stateChangeSuccess', function(event, toState) {
      if (prevState.name === 'app.line-items.line-item' && _.startsWith(toState.name, 'app.creatives.')) {
        ctrl.animationClass = "animateTransition animateTransitionUp";
      } else if (prevState.name === 'app.creatives.creative' && toState.name === 'app.line-items.line-item') {
        ctrl.animationClass = "animateTransition animateTransitionDown";
      } else {
        ctrl.animationClass = '';
      }
      prevState = toState;
    });

    function emptyOut() {
      ctrl.specialPanelVisible = false;
      ctrl.showNetworks = false;
      ctrl.showNotifications = false;
      ctrl.showLast = false;
    }

    function evaluateVisibility() {
      ctrl.specialPanelVisible = ctrl.showNetworks || ctrl.showNotifications || ctrl.showLast;
    }

    function doToggling(param, func) {
      if (ctrl.specialPanelVisible && !ctrl[param]) {
        ctrl.ignoreClearOut = true;
      }
      if (ctrl[param]) {
        emptyOut();
        return;
      }
      emptyOut();
      ctrl[param] = true;
      if (ctrl[param] && _.isFunction(func)) {
        func();
      }
      evaluateVisibility();
    }

    ctrl.clearOut = function() {
      if (!ctrl.ignoreClearOut) {
        emptyOut();
        evaluateVisibility();
      }
      ctrl.ignoreClearOut = false;
    };
    emptyOut();

    ctrl.toggleNotifications = function() {
      doToggling('showNotifications', ctrl.notificationsOn);
    };

    ctrl.switchToMaui = function() {
      const sessionData = Session.obtainData();
      const uri = MauiConfig.obtain().getUri($location.path())
        + "?accessToken=" + sessionData.accessToken
        + "&refreshToken=" + sessionData.refreshToken
        + "&username=" + sessionData.username
        + "&masqueraderUsername=" + sessionData.masqueraderUsername
        + "&expiresIn=" + sessionData.expires
        + "&context=" + LocalNetworkProfile.getNetworkTag();
      $window.open(uri);
    };
    ctrl.canSwitchToMaui = localUserProfile.canSwitchToMaui() || LocalUserPermissions.has('AX_ADVERTISER', 'nyk7jyc1p3g2czy8');

    ctrl.toggleLast = function() {
      doToggling('showLast');
    };

    ctrl.toggleChangelog = function() {
      $uibModal.open({
        template: releaseNotesTemplate,
        controller: function() {
          const ctrl = this;
          ctrl.apps = getReleaseNoteApps();
        },
        controllerAs: 'modalCtrl'
      });

      localUser = localUserProfile.get();
      localUser.profile.adnPrefs.lastReleaseNotesRead = moment().unix();
      UserProfile.save({
        id: localUser.userId,
        profile: {
          adnPrefs: localUser.profile.adnPrefs
        }
      }, function(apiUser) {
        localUser = localUserProfile.safeSaveProfile('adnPrefs', apiUser.profile.adnPrefs);
        ctrl.showReleaseNotesNotification = false;
      });
    };

    $scope.$on(SESSION_EVENT.userProfileUpdate, function() {
      localUser = localUserProfile.get();
      const newReleaseHighlight = _.get(localUser, ['profile', 'adnPrefs', 'showReleaseHighlight'], 'SHOW');
      if (newReleaseHighlight === 'HIDE' && ctrl.showReleaseNotesNotification) {
        ctrl.showReleaseNotesNotification = false;
      }
      if (newReleaseHighlight === 'SHOW' && !ctrl.showReleaseNotesNotification) {
        checkReleaseNotes();
      }
    });

    ctrl.toggleNetworks = function() {
      doToggling('showNetworks');
    };

    if (_.startsWith($location.host(), 'localhost') && !_.startsWith(ApiConfig.obtain().getId(), 'localhost')) {
      ctrl.env = ApiConfig.obtain().getId().split("_")[0].toUpperCase();
    }

    ctrl.permissions = _.mapValues(PERMISSIONS, function(permission) {
      return userPermissions && userPermissions.hasPermission(permission.apiKey);
    });

    ctrl.workspaces = WorkspaceRegistry.workspaces;

    ctrl.uiLogo = _.get(regUrl, ['logo']) || LocalNetworkProfile.get('adnUiLogo');

    $rootScope.$on(AUTH_EVENT.networkChange, function(eventData, network) {
      ctrl.currentNetwork = network;
    });

    ctrl.releaseId = BUILD_INFO.gitSha.substring(0, 10);
  }
}

const MODULE_NAME = 'base';

angular.module(MODULE_NAME, [
  uiRouter,
  localNetworkProfile,
  localUserPermissions,
  workspaceRegistry,
  sessionInfo,
  simpleModule,
  nudeModule,
  networkTabDir,
  lastVerbedTabs,
  notifTab,
  releaseNotesDisplayComponent,
  releaseNotesHelper,
  masqBanner,
  clickOutside,
  ghosteryBanner,
  browserUpdateBanner
])
  .config(($stateProvider) => {
    $stateProvider.state('app', {
      abstract: true,
      template: template,
      controller: Base,
      controllerAs: 'base'
    });
  });

export default MODULE_NAME;
